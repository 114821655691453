@import '../../variables.scss';

.legendContainer {
	padding-top: 48px;
}

.legendItemContainer {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
}

.answerTextContainer {
	display: flex;
	gap: 10px;
	width: 50%;

	.answerText {
		font-size: 14px;
		font-weight: 700;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.votesCountButtonContainer {
	display: flex;
	gap: 6px;
}

.votesCount {
	display: flex;
	justify-content: flex-end;
}
